<template>
  <v-container v-show="showForm">
    <the-page-title
      title="POTVRDA E-MAIL ADRESE ZA SLANJE OBAVIJESTI"
      :steps="false"
    />
    <v-row class="justify-center">
      <div class="pa-6 pt-4 pb-4 mb-8 elevation-6 rounded fluid nbr-mw800">
          <v-checkbox
            :dense="denseField"
            v-model="form.verify.value"
            :error-messages="form.verify.error"
            @change="form.verify.error=''"
          >
            <template v-slot:label>
              <div>
                Potvrđujem <span style="color: red; font-size: 1.1em"><b>{{ email }}</b></span> e-mail adresu za slanje obavijesti za dijete <span style="color: red; font-size: 1.1em"><b>{{ child_name }}</b></span>
              </div>
            </template>
          </v-checkbox>
      </div>
    </v-row>
    <v-row class="justify-center">
      <v-card class="pa-2 elevation-6 blue lighten-3 lighten-4 nbr-mw800 red">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="postForm"
          >Potvrdi email</v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import ThePageTitle from '../components/ThePageTitle'
import axios from 'axios';
import common from '../common.js';

export default {
  name: 'Cancel',
  mixins: [ common ],
  created () {
    this.getFormData();
  },

  computed: {
    denseField () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true;
        default: return false;
      }
    }
  },

  methods: {
    getFormData() {
      this.showOverlay();
      axios.post('', { action: 'getEmail', data: this.formData() })
        .then((res) => {
          if (this.cmnAxiosPost(res)) {
            this.email = res.data.email;
            this.child_name = res.data.child_name;
          }
        })
        .catch((error) => { this.cmnAxiosError(error, false); })
        .then(() => { this.hideOverlay(); this.showForm = true; });
    },
    postForm() {
      this.showOverlay();
      axios.post('', { action: 'verifyEmail', data: this.formData() })
        .then((res) => {
          if (this.cmnAxiosPost(res)) {
            this.showInfo({ title: 'INFO', text: res.data.message, button: true });
          }
        })
        .catch((error) => { this.cmnAxiosError(error, true); })
        .then(() => { this.hideOverlay(); });
    }
  },

  data() {
    return {
      form: {
        hash: { value: this.$route.params.hash},
        verify: { value: false, error: '' }
      },
      //
      email: null,
      child_name: null,
      showForm: false
    }
  },

  components: {
    ThePageTitle
  }
};

</script>